body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

[data-amplify-authenticator] [data-amplify-router] fieldset {
  display: none;
}
[data-amplify-authenticator] .amplify-divider {
  display: none;
}
[data-amplify-authenticator] form div:not(.federated-sign-in-container) button {
  display: none;
}
[data-amplify-authenticator] form + [data-amplify-footer] {
  display: none;
}