.pdfscreen {
    display: flex;
    justify-content: center;
    align-items: center;
}

#customAlert {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    max-width: 400px;
    min-height: 100px;
    background-color: lightblue;
    padding: 20px;
    border-radius: 10px;
    z-index: 1002;
    text-align: center;
    font-size: 20px;
}